* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.hero{
  text-align: center;
  background-image: url('./pics/hero2.jpg');
  background-size: cover;
  background-position: center;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-filter: brightness(20%);
  
}

.hero h1{
  color: white;
  font-size: 5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* font-family: 'Times New Roman', Times, serif; */
}

.hero p{
  color: white;
  font-size: 1rem;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

}

.species{
  display: grid; /* Changed from flex to grid */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Creates a responsive grid */
  gap: 20px;
  padding: 20px;
  width: 70%;
  margin: 0 auto;
}

.species-item{
  text-align: center;
  position: relative;
  /* border: 1px solid #ccc; */
  border-radius: .5rem;

  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.species-item:hover{
  transform: scale(1.05);
  transition: transform 0.3s;
  cursor: pointer;
}

.species-item img{
  width: 100%;
  height: 300px;
  border-radius: 1rem 1rem 0 0;
  
  object-fit: cover;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
}

.references {
  width: 60%;
  margin: 1rem auto;
}

.references h1{
  text-align: center;
}

.references li{
  margin: .3rem 0rem;
}

.tag {
  position: absolute;
  left: -6px;
  top: -4px;
  color: #ffffff;
  padding: .4rem;
  background-color: green;
  border-radius: .2rem;
}

.name {
  background-color: #333;
  color: white;
  padding: .2rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

@media (max-width: 768px) {
  .species {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .species-item img{
    height: 200px;
  }
  

  .hero h1{
    font-size: 3rem;
  }

  .hero p{
    font-size: 0.8rem;
  }

  .references {
    width: 95%;
    font-size: 1rem;
  }

}