.nav-bar{
    background-color: #333;
    color: white;
    
    display: flex;
    justify-content: center;
}

.nav-list{
    display: flex;
    justify-content: center;
    list-style: none;
    /* gap: 20px; */

}

.nav-item{
    color: white;
    height: 100%;
    font-size: 1.2rem;
    cursor: pointer;
    transition: color 0.3s;
    /* margin: 0 -2px; */
    padding: 10px;
    position: relative; /* Ensure the dropdown is positioned relative to the nav item */
}

.nav-item:hover{
    color: #ddd;
    /* transform: scale(1.1);
    transition: transform 0.3s; */
    background-color: #5e5e5e;
    
}

.nav-item ul {
    display: none; /* Hide the dropdown by default */
    position: absolute; /* Position it absolutely */
    background-color: #333; /* Background color */
    color: white; /* Text color */
    padding: 10px; /* Padding around the items */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a shadow for depth */
    z-index: 1000; /* Ensure it appears above other elements */
}

.nav-item:hover ul {
    display: block; /* Show the dropdown on hover */
}

.nav-item ul li {
    list-style: none;
    padding: 8px 12px; /* Padding for dropdown items */
    cursor: pointer; /* Change cursor to pointer */
}

.nav-item ul li:hover {
    background-color: #555; /* Change background on hover */
}

@media (max-width: 768px) {
    .nav-item{
        font-size: 1rem;
    }
}
