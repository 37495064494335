/* src/components/Family.css */

body {
    font-family: Arial, sans-serif; /* Set a default font for the body */
}

.family-table {
    width: 80%; /* Make the table full width */
    border-collapse: collapse; /* Remove space between borders */
    margin: 20px auto;
}

.family-table th, .family-table td {
    border: 1px solid #ddd; /* Add a light border to table cells */
    padding: 8px; /* Add padding inside cells */
    text-align: left; /* Align text to the left */
}

.family-table th {
    background-color: #4CAF50; /* Green background for the header */
    color: white; /* White text for the header */
}

.family-table tr:nth-child(even) {
    background-color: #f2f2f2; /* Light gray background for even rows */
}

.family-table tr:hover {
    background-color: #ddd; /* Change background on hover */
}

h1 {
    margin-top: 1rem;
    color: #333; /* Darker color for the heading */
    text-align: center; /* Center the heading */
}

@media (max-width: 768px) {
    .family-table {
        max-width: 100%;
        font-size: .5rem;
    }
}


